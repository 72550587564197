.table-header {
  background-color: #707070;
  color: white;
  padding: 12px;
  border: 0.1px solid rgb(146, 146, 146);
}

.table-cell {
  color: black;
}

.field-label {
  color: #4c4ca4;
}

.form-checkbox {
  padding: 22px 0px 0px 8px;
}

.disableFilterOprator .k-filtercell-operator {
  display: none !important;
}